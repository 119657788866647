// App.js

import React from 'react';
import Routes from './Routes';
import { WalletProvider } from '../src/pages/Wallet/Context/WalletContext';

function App() {
  return (
    <>

      <WalletProvider>
        <Routes />
      </WalletProvider>
    </>
  );
}

export default App;
