import { useEffect, useState } from "react";

import { EditBussinessCards } from "../../../../../../api/userRegestration/CollectionForm";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchBussinessCardDetails } from "../../../../../../api/userRegestration/CollectionForm";

export default function EditBussinessCard({
  productDetails,
  toggleEditMode,
  updateProductDetails,
  businessCardId,

}) {
  //


  const [formData, setFormData] = useState({
    name: productDetails.name,
    Occupation: productDetails?.identification,
    Location: productDetails?.location,
    Email: productDetails?.email,
    Company: productDetails?.companyName,
    Mobile: productDetails?.phoneNumber,
  });
  //el
  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === "checkbox" ? checked : value;
    setFormData({ ...formData, [name]: newValue });
    console.log("handleInputChange called");
  };
  //

  // Inside EditBussinessCard component, after successfully submitting the form

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const updatedBussinessinfo = {
        businessCardId: businessCardId,
        name: formData.name,
        identification: formData.Occupation,
        location: formData.Location,
        email: formData.Email,
        phoneNumber: formData.Mobile,
        companyName: formData.Company,
        position: "sector bscs",
        website: "https://www.rozee.pk/",
        socialLink: "https://www.rozee.pk/"
      };
      const Product = await EditBussinessCards(updatedBussinessinfo);
      console.log("post:", Product);
  
      // Show a success toast notification
      toast.success("Business card updated successfully", {
        position: "top-right",
        autoClose: 500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
  
      // Fetch updated business card details
      fetchBussinessCardDetails(businessCardId)
        .then((response) => {
          updateProductDetails(response.result)
          toast.success("you can now view the bussiness card with changes", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
     
     
     
        })
        .catch((error) => {
          console.error("Error:", error);
        });
  
      // updateProductDetails(Product);
    } catch (error) {
      console.error("Error in :", error.message);
    }
  };
  


  return (
    <>
      <div className="bg-layout shadow-md drop-shadow-md lg:w-[155vh] mb-[7rem] lg:ml-[268px] relative top-[1rem] h-max">
        <h1 className="fade-in-left font-all text-center font-semibold lg:text-[22px] lg:mt-[2rem] mb-8">
          Edit Business Card
        </h1>

        <form onSubmit={handleSubmit}>
          <div className="fade-in-right bg-white drop-c  w-max mx-auto h-max mt-8">
            <h1 className="flex flex-row gap-6 font-all text-left ml-4 font-semibold lg:text-[18px] relative top-[1rem] ">
              name : <span className="font-light">{productDetails?.name}</span>
            </h1>

            <div className="flex flex-col py-6">
              <div className="overflow-x-auto sm:mx-0.5 lg:mx-0.5">
                <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="overflow-hidden">
                    <table className="min-w-full">
                      <tbody>
                        <tr className="">
                          <td className="text-sm font-all text-gray-900 font-semibold px-6 pb-4 whitespace-nowrap">
                            name:
                          </td>
                          <td className="text-sm text-gray-900 font-light px-6 pb-4 whitespace-nowrap">
                            <input
                              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                              name="name"
                              type="text"
                              value={formData.name}
                              onChange={handleInputChange}
                            />
                          </td>
                        </tr>

                        <tr className="">
                          <td className="text-sm font-all text-gray-900 font-semibold px-6 pb-4 whitespace-nowrap">
                            Occupation / identification:
                          </td>
                          <td className="text-sm text-gray-900 font-light px-6 pb-4 whitespace-nowrap">
                            <input
                              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                              name="Occupation"
                              type="text"
                              value={formData.Occupation}
                              onChange={handleInputChange}
                            />
                          </td>
                        </tr>
                        <tr className="bg-white ">
                          <td className="text-sm font-all text-gray-900 font-semibold px-6 pb-4 whitespace-nowrap">
                            Location
                          </td>
                          <td className="text-sm text-gray-900 font-light px-6 pb-4 whitespace-nowrap">
                            {/* {productDetails?.location} */}
                            <input
                              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                              type="text"
                              name="Location"
                              value={formData.Location}
                              onChange={handleInputChange}
                            />
                          </td>
                        </tr>
                        <tr className="">
                          <td className="text-sm font-all text-gray-900 font-semibold px-6 pb-4 whitespace-nowrap">
                            Email id
                          </td>
                          <td className="text-sm text-gray-900 font-light px-6 pb-4 whitespace-nowrap">
                            {/* {productDetails?.email} */}
                            <input
                              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                              type="text"
                              name="Email"
                              value={formData.Email}
                              onChange={handleInputChange}
                            />
                          </td>
                        </tr>
                        <tr className="bg-white ">
                          <td className="text-sm font-all text-gray-900 font-semibold px-6 pb-4 whitespace-nowrap">
                            Company Name
                          </td>
                          <td className="text-sm text-gray-900 font-light px-6 pb-4 whitespace-nowrap">
                            {/* {productDetails?.companyName} */}

                            <input
                              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                              type="text"
                              name="Company"
                              value={formData.Company}
                              onChange={handleInputChange}
                            />
                          </td>
                        </tr>
                        <tr className="bg-white ">
                          <td className="text-sm font-all text-gray-900 font-semibold px-6 pb-4 whitespace-nowrap">
                            Mobile Number
                          </td>
                          <td className="text-sm text-gray-900 font-light px-6 pb-4 whitespace-nowrap">
                            {/* {productDetails?.phoneNumber} */}

                            <input
                              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                              type="text"
                              name="Mobile"
                              value={formData.Mobile}
                              onChange={handleInputChange}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="button-action flex flex-col items-center lg:mt-[2rem] dro-c lg:w-[45%] mx-auto">
            <button
              type="submit"
              className="bg-[#CB2229] text-white rounded-full font-all p-[6px] lg:mt-[0.5rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out relative"
            >
              Update Bussiness card
            </button>
            <button
              onClick={toggleEditMode}
              className="mb-6 border-[1px] border-[#CB2229] text-black rounded-full font-all p-[6px] lg:mt-[0.5rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out relative  "
            >
              Go back to bussiness card
            </button>
          </div>
        </form>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      {/* )} */}
    </>
  );
}
