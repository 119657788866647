// WalletContext.js

import React, { createContext, useContext, useState, useEffect } from 'react';
import {fetch_wallet_info} from "../../../api/userRegestration/CollectionForm"

const WalletContext = createContext();


export const WalletProvider = ({ children }) => {
  const [walletData, setWalletData] = useState();
// 
  useEffect(() => {
    const currentDate = new Date();
    console.log(`Wallet - context created/updated at: ${currentDate}`);
  }, [walletData]); 
  
  // helper function for the fetching user wallet balance
const fetch_wallet_balance = () => {
    fetch_wallet_info()
      .then((response) => {
        console.log('API Response:', response);
        setWalletData(response.result);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };
// 



// here we are executing the function once redered 
  useEffect(() => {
 
    fetch_wallet_balance();
  }, []);

 


 
  return (
    <WalletContext.Provider  value={{ walletData, setWalletData }}>
      {children}
    </WalletContext.Provider>
  );
};

export const useWallet = () => {
  const context = useContext(WalletContext);
  if (!context) {
    throw new Error('useWallet must be used within a WalletProvider');
  }
  return context;
};
