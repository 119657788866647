import React, { useEffect } from 'react';

import { useState } from "react";
import ExploreInputMain from './ExploreInput/ExploreInputMain';
import ExploreAlllmain from './ExploreAll/ExploreAlllmain';
import ExplorePostMain from "./ExplorePost/ExplorePostMain";
// import ExploreProfile from './ExploreProfile/ExploreProfile';
import Profilemain from '../FindProduct/Profile/Profilemain';
import Postmain from '../FindProduct/Post/Postmain';
function ExploremainComponent() {
  const tabsData = [
{
label:"All",
content:<ExploreAlllmain/>
},{
label:"INPUT",
content:<ExploreInputMain/>
},{
  label:"Post",
  content:<Postmain/>
},
// {
//   label:"Profile",
//   content:<Profilemain/>
// }
]

const [activeTabIndex, setActiveTabIndex] = useState(0);

useEffect(() => {
  // Scroll to the top of the page when the component mounts
  window.scrollTo(0, 0);
}, []);

      
  return (
    <>
       <div className="  bg-layout shadow-md drop-shadow-md lg:w-[155vh]  mb-[7rem]  lg:ml-[268px] relative top-[1rem] fade-in-left ">
        <div className=" ml-4 flex space-x-3 border-b justify-center gap-[5rem]">
          {/* Loop through tab data and render button for each. */}
          {tabsData.map((tab, idx) => {
            return (
              <button
                key={idx}
                className={`py-2 border-b-4 transition-colors duration-300 text-[19px] font-all font-semibold ${
                  idx === activeTabIndex
                    ? "border-[#CB2229]  "
                    : "border-transparent hover:border-gray-200"
                }`}
                // Change the active tab on click.
                onClick={() => setActiveTabIndex(idx)}
              >
                {tab.label}
              </button>
            );
          })}
        </div>
        {/* Show active tab content. */}
        <div className=" ">
          <p>{tabsData[activeTabIndex].content}</p>
        </div>
      </div>

    </>
  )
}

export default ExploremainComponent
